import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './containers/Main';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Header from './components/header/Header';
import TestComponent from './components/test/TestComponent';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/test" component={TestComponent} />
        <Route path="*" component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
