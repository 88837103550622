// Your Summary And Greeting Section

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  username: "Dharmin Patel",
  title: "Hi, I'm Dharmin",
  subTitle: emoji("A passionate Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks."),
  resumeLink: ""
};

// Social Media Link

const socialMediaLinks = {

  github: "https://github.com/Dharmin2108",
  linkedin: "https://www.linkedin.com/in/dharmin-patel-84612616b/",
  gmail: "dharminpatel99@gmail.com",
  gitlab: "https://gitlab.com/dharminpatel99",
  facebook: "https://www.facebook.com/dharmin.patel.948/"
  // Instagram and Twitter are also supported in the links!
};

// Your Skills Section

const skillsSection = {
  title: "What I do?",
  subTitle: "FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji("⚡ Develop Front end / User Interfaces for web and mobile applications"),
    emoji("⚡ Develop Back end API's"),
    emoji("⚡ Integration of API's to user interface")
  ],

  /* Make Sure You include correct Font Awesome Classname to view your icon
  https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "angular",
      fontAwesomeClassname: "fab fa-angular"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "kubernetes",
      fontAwesomeClassname: "fas fa-dharmachakra"
    }
  ]
};

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Backend/Programming",
      progressPercentage: "90%"
    },
    {
      Stack: "Frontend",
      progressPercentage: "75%"
    }
  ]
};

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "Fresh To Home",
      companylogo: require("./assets/images/fth.png"),
      date: "July 2020 – Present",
      desc: "Working as a software engineer",
      // descBullets: [
      //   "Point 1",
      //   "point 2"
      // ]
      color: '#7755A2',
      colorthief: false, //true if you want to get color from image
    },
    {
      role: "Software Engineer",
      company: "Mountblue Technologies",
      companylogo: require("./assets/images/mountblueLogo.png"),
      date: "February 2019 – June 2020",
      desc: "Worked as Software Engineer as an employee of Mountblue Technologies for the client Fresh To Home Foods Pvt. Ltd.",
      color: '#52FFFF',
      colorthief: false,
    }
  ]
};

const projectSection = {

  title: "Projects",
  subtitle: "I love to develop cool stuff.",

  projects: [
    {
      url: "https://gitlab.com/dharminpatel99/scorebook",
      title: "Cricket Scorebook",
      description: "A place where you can keep track of score of matches digitally."
    }
  ]
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle: "Just want to say hi? My Inbox is open for all.",
  number: "+910000000000",
  email_address: "dharminpatel99@gmail.com"
};

export { greeting, socialMediaLinks, skillsSection, techStack, workExperiences, projectSection, contactInfo };