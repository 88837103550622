import React from "react";
import "./Projects.css";
import ProjectCard from "../../components/projectCard/ProjectCard";
import { projectSection } from "../../portfolio";
import { Fade } from "react-reveal";

export default function Projects() {
  return (
    <Fade bottom duration={1000} distance="20px">
    <div className="main" id="projects">
      <div className="project-header">
        <h1 className="project-header-text">{projectSection.title}</h1>
        <p className="subTitle project-subtitle">{projectSection.subtitle}</p>
      </div>
      <div className="project-main-div">
        <div className="project-text-div">
          {projectSection.projects.map(project => {
            return (
              <ProjectCard
                project={{
                  url: project.url,
                  title: project.title,
                  description: project.description
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
    </Fade>
  );
}
