import React, { Component } from 'react'
import Header from '../components/header/Header'
import Greetings from './greeting/Greetings'
import Skills from './skills/Skills'
import Proficiency from './proficiency/Proficiency'
import WorkExperience from './workExperience/WorkExperience';
import Projects from './projects/Projects'
import Contact from './contact/Contact'
import Footer from '../components/footer/Footer'
import Top from './top/Top'

export default class Main extends Component {
  render() {
    return (
      <div>
        <Header />
        <Greetings />
        <Skills />
        <Proficiency />
        <WorkExperience />
        <Projects />
        <Contact />
        <Footer />
        <Top />
      </div>
    )
  }
}
