import React from 'react'
import Header from '../header/Header'

export default function TestComponent() {
  return (
    <div>
      <Header />
      This is test component
    </div>
  )
}
