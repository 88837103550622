import React from "react";
import "./ProjectCard.css";

export default function ProjectCard({ project }) {
  function openUrlInNewTab(url) {
    if(url !== undefined) {
      var win = window.open(url, "_blank");
      win.focus();
    }
  }

  return (
    <div>
      <div class="project-container" onClick={() => openUrlInNewTab(project.url)}>
        <a class="project-card" href="#project" >
          <h3 className="project-title">{project.title}</h3>
          <p class="small">{project.description}</p>
          <div class="go-corner" >
            <div class="go-arrow">→</div>
          </div>
        </a>
      </div>
    </div>
  );
}
